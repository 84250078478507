<main>
    <div class="container-fluid">
        <h4></h4>
        <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item active">ONBOARD PRACTITIONER</li>
        </ol>
        <div class="card shadow-lg border-0 rounded-lg mt-3">
            <div class="card-body">
                <h6 class="card-title font-weight-bold mb-4">Onboard Practitioner</h6>
                <form class="ng-untouched ng-pristine" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row mb-4">
                        <div class="col">
                            <label class=" mb-1" for="name">First name</label>
                            <input formControlName="firstname" placeholder="Enter Firstname" class="form-control" id="firstname" type="text" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                <div *ngIf="f.firstname.errors.required">First name is required</div>
                            </div>
                        </div>
                        <div class="col">
                            <label class=" mb-1" for="name">Last name</label>
                            <input formControlName="lastname" placeholder="Enter Lastname" class="form-control" id="lastname" type="text" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                <div *ngIf="f.lastname.errors.required">Last name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <label class="mb-1" for="inputEmailAddress">Email</label>
                            <input formControlName="email" placeholder="Enter Email" class="form-control " id="inputEmailAddress" type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
                        </div>
                        <div class="col">
                            <label class="mb-1" for="phoneNumber">Phone number</label>
                            <input formControlName="phoneNumber" placeholder="Enter PhoneNumber" class="form-control" id="phoneNumber" type="text" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />
                            <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                                <div *ngIf="f.phoneNumber.errors.required">Phone Number is required</div>
                            </div>
                        </div>
                    </div>
                    <!-- <p *ngIf="error" class="text-danger">Email id already in use.</p> -->

                    <div class="text-right m-2">
                        <button class="btn btn-danger m-2" type="submit" (click)="onCancel()">Cancel</button>
                        <button class="btn btn-primary m-2" type="submit">Save</button>
                    </div>
                </form>
                <!-- <p *ngIf="status" class="text-success">Added successfully</p> -->
            </div>
        </div>
    </div>
</main>