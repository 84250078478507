import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalProvider } from '../utility/global.service';
import { HttpService } from '../utility/http.service';
import { FORM } from './onboard-practitioner.model';

@Injectable({
    providedIn: 'root'
  })

export class OnboardPractitionerService {
    isLoading: any;
    constructor(
        public global: GlobalProvider,
        private httpSvc: HttpService
    ){}
    submit(url,regForm) : Observable<any>{
        let headers = new HttpHeaders();
        const body=JSON.stringify(regForm);
        return this.httpSvc.post(url ,body,headers,true)
    }
 }