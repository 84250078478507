import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavMenuService } from '../nav-menu/nav-menu.service';
import { SideMenuService } from '../side-menu/side-menu.service';
import { GlobalProvider } from '../utility/global.service';
import { ConfirmedValidator } from '../shared/confirmed.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  submitted:boolean;
  
  constructor( private formBuilder: FormBuilder,private global: GlobalProvider,
    public nav:NavMenuService,
    public menu:SideMenuService, private router:Router) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { 
      validator: ConfirmedValidator('newPassword', 'confirmPassword')
    });
    this.nav.hide();
    this.menu.hide();
  }
  get forgotPassword() { return this.forgotPasswordForm.controls; }
  onSubmit() {
    this.submitted = true;
    if(this.forgotPasswordForm.valid){
      this.router.navigate(['/login']);

    }
  }
}
