import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NavMenuService } from '../nav-menu/nav-menu.service';
import { SideMenuService } from '../side-menu/side-menu.service';
import { AlertService } from '../utility/alert.service';
import { GlobalProvider } from '../utility/global.service';
import { LoginRequest, LoginResponse } from './login.model';
import { LoginService } from './login.service';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {



  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  loader: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private alertService: AlertService,
    private global: GlobalProvider,
    public nav:NavMenuService,
    public menu:SideMenuService

  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

  this.nav.hide();
  this.menu.hide();

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    console.log(this.f.username.value + ' ' + this.f.password.value);
    let loginReq: LoginRequest = {
      username: this.f.username.value,
      password: this.f.password.value,
      applicationId: environment.applicationID,
    };

    this.loginService.login(loginReq)
      .subscribe((data: LoginResponse) => {
        console.log(data);
        console.log(data.data);
        if (data.status === 'success') {
          this.global.accessToken = data.data.accessToken.response.token;
          localStorage.setItem('accessToken', this.global.accessToken);
          this.router.navigate(['/practitioner']);
          let myRawToken = localStorage.getItem('accessToken');

          const expirationDate = helper.getTokenExpirationDate(myRawToken);

          const diffInMilliseconds = Math.abs(new Date(expirationDate).valueOf() - new Date().valueOf());
          localStorage.time = diffInMilliseconds;
        }
      },
        error => {  
          this.alertService.error(error);
          this.loading = false;
        });
    // this.router.navigate(['/dashboard']);
  }
}
