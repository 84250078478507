<!---- <div id="layoutSidenav_content">-->
<main>
    <div class="container-fluid">
        <h1 class="mt-4">Dashboard</h1>
        <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item active">Dashboard</li>
        </ol>

        <div class="row">
            <div class="col">
                <div class="drag-container">
                    <div class="section-heading">New Leads</div>

                    <div cdkDropList #idNewLeads="cdkDropList" [cdkDropListData]="newLeads" [cdkDropListConnectedTo]="[idAppointmentsBooked,idServicesSold,idPatientNoShow, idFollowUp]" class="item-list h-viewport" (cdkDropListDropped)="drop($event,'New leads')">
                        <div *ngFor="let item of newLeads" cdkDrag>
                            <div class="card mb-2 bl-brown">
                                <div class="card-body">
                                    <p class="small"><b>{{item.profile.name}}</b></p>
                                    <p class="small">{{item.profile.city}} - {{item.profile.country}}</p>
                                    <div class="contact">
                                        <p class="small"> <i class="fa fa-clock-o" aria-hidden="true"></i> {{item.preferredTimeSlot}}</p>
                                        <p class="small"><i class="fa fa-phone" aria-hidden="true"></i> {{item.profile.phone}}</p>
                                        <p class="small"> <i class="fa fa-envelope" aria-hidden="true"></i> {{item.profile.email}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="h-100" *ngIf="newLeads.length === 0"></div>

                    </div>
                </div>
            </div>
            <div class="col">
                <div class="drag-container">
                    <div class="section-heading">Appointments Booked</div>

                    <div cdkDropList #idAppointmentsBooked="cdkDropList" [cdkDropListData]="appointmentsBooked" [cdkDropListConnectedTo]="[idNewLeads,idServicesSold, idPatientNoShow, idFollowUp]" class="item-list h-viewport" (cdkDropListDropped)="drop($event,'Appointments booked')">
                        <div *ngFor="let item of appointmentsBooked" cdkDrag>
                            <div class="card mb-2 bl-purple">
                                <div class="card-body">
                                    <p class="small"><b>{{item.profile.name}}</b></p>
                                    <p class="small">{{item.profile.city}} - {{item.profile.country}}</p>
                                    <div class="contact">
                                        <p class="small"> <i class="fa fa-clock-o" aria-hidden="true"></i> {{item.preferredTimeSlot}}</p>
                                        <p class="small"><i class="fa fa-phone" aria-hidden="true"></i> {{item.profile.phone}}</p>
                                        <p class="small"> <i class="fa fa-envelope" aria-hidden="true"></i> {{item.profile.email}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="h-100" *ngIf="appointmentsBooked.length === 0"></div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="drag-container">
                    <div class="section-heading">Services Sold</div>

                    <div cdkDropList #idServicesSold="cdkDropList" [cdkDropListData]="servicesSold" [cdkDropListConnectedTo]="[idNewLeads,idAppointmentsBooked,idPatientNoShow, idFollowUp]" class="item-list h-viewport" (cdkDropListDropped)="drop($event,'Services Sold')">
                        <div *ngFor="let item of servicesSold" cdkDrag>
                            <div class="card mb-2 bl-green">
                                <div class="card-body">
                                    <p class="small"><b>{{item.profile.name}}</b></p>
                                    <p class="small">{{item.profile.city}} - {{item.profile.country}}</p>
                                    <div class="contact">
                                        <p class="small"> <i class="fa fa-clock-o" aria-hidden="true"></i> {{item.preferredTimeSlot}}</p>
                                        <p class="small"><i class="fa fa-phone" aria-hidden="true"></i> {{item.profile.phone}}</p>
                                        <p class="small"> <i class="fa fa-envelope" aria-hidden="true"></i> {{item.profile.email}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="h-100" *ngIf="servicesSold.length === 0"></div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="drag-container">
                    <div class="section-heading">Patient No-Show</div>
                    <div cdkDropList #idPatientNoShow="cdkDropList" [cdkDropListData]="patientNoShow" [cdkDropListConnectedTo]="[idNewLeads,idAppointmentsBooked,idServicesSold, idFollowUp]" class="item-list h-viewport" (cdkDropListDropped)="drop($event,'Patient No-Show')">
                        <div *ngFor="let item of patientNoShow" cdkDrag>
                            <div class="card mb-2 bl-red">
                                <div class="card-body">
                                    <p class="small"><b>{{item.profile.name}}</b></p>
                                    <p class="small">{{item.profile.city}} - {{item.profile.country}}</p>
                                    <div class="contact">
                                        <p class="small"> <i class="fa fa-clock-o" aria-hidden="true"></i> {{item.preferredTimeSlot}}</p>
                                        <p class="small"><i class="fa fa-phone" aria-hidden="true"></i> {{item.profile.phone}}</p>
                                        <p class="small"> <i class="fa fa-envelope" aria-hidden="true"></i> {{item.profile.email}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="h-100" *ngIf="patientNoShow.length === 0"></div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="drag-container">
                    <div class="section-heading">Follow Up</div>
                    <div cdkDropList #idFollowUp="cdkDropList" [cdkDropListData]="followUp" [cdkDropListConnectedTo]="[idNewLeads,idAppointmentsBooked,idServicesSold, idPatientNoShow]" class="item-list h-viewport" (cdkDropListDropped)="drop($event,'Followup')">
                        <div *ngFor="let item of followUp" cdkDrag>
                            <div class="card mb-2 bl-yellow">
                                <div class="card-body">
                                    <p class="small"><b>{{item.profile.name}}</b></p>
                                    <p class="small">{{item.profile.city}} - {{item.profile.country}}</p>
                                    <div class="contact">
                                        <p class="small"> <i class="fa fa-clock-o" aria-hidden="true"></i> {{item.preferredTimeSlot}}</p>
                                        <p class="small"><i class="fa fa-phone" aria-hidden="true"></i> {{item.profile.phone}}</p>
                                        <p class="small"> <i class="fa fa-envelope" aria-hidden="true"></i> {{item.profile.email}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="h-100" *ngIf="followUp.length === 0"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<!---- <footer class="py-4 bg-light mt-auto">
                <div class="container-fluid">
                    <div class="d-flex align-items-center justify-content-between small">
                        <div class="text-muted">Copyright &copy; Your Website 2020</div>
                        <div>
                            <a href="#">Privacy Policy</a>
                            &middot;
                            <a href="#">Terms &amp; Conditions</a>
                        </div>
                    </div>
                </div>
            </footer>-->
<!-- </div> -->