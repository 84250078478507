import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalProvider {

  public accessToken:string;
  public authUrl= environment.apiUrl + 'uaa/login';
  public URL='https://api.insights.farmtohomes.net';
  public patientServiceEndpoint = environment.apiUrl + 'patient/form';


  constructor() { 
    this.accessToken = localStorage.getItem('accessToken');
  }
  
}
