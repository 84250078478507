import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { GlobalProvider } from './global.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    // private alertSvc: AlertService,
    public global: GlobalProvider
  ) { }

  post(endpoint: string, reqBody: any, headers: HttpHeaders, auth: boolean): Observable<any> {
    if (auth) {
      headers = headers.set('Authorization', this.global.accessToken);
    }
    return this.http.post(endpoint, reqBody, { headers: headers }).
      pipe(catchError(error => {
        let httpError: string;
        if (error.error instanceof ErrorEvent) {
          httpError = `Error: ${error.error.message}`;
        } else {
          httpError = this.getServerErrorMessage(error);
        }
        console.log(httpError);
        return throwError(httpError);
      })
      )
  }

  put(endpoint: string, reqBody: any, headers: HttpHeaders, auth: boolean): Observable<any> {
    if (auth) {
      headers = headers.set('Authorization', this.global.accessToken);
    }
    return this.http.put(endpoint, reqBody, { headers: headers }).
      pipe(catchError(error => {
        let httpError: string;
        if (error.error instanceof ErrorEvent) {
          httpError = `Error: ${error.error.message}`;
        } else {
          httpError = this.getServerErrorMessage(error);
        }
        console.log(httpError);
        return throwError(httpError);
      })
      )
  }

  get(endpoint: string, auth: boolean): Observable<any> {
    let headers = new HttpHeaders();

    if (auth) {
      headers = headers.set('Authorization', this.global.accessToken);
    }
    return this.http.get(endpoint, { headers: headers }).
      pipe(catchError(error => {
        console.log(error);
        let httpError: string;
        if (error.error instanceof ErrorEvent) {
          httpError = `Error: ${error.error.message}`;
        } else {
          httpError = this.getServerErrorMessage(error);
        }
        console.log(httpError);
        return throwError(httpError);
      })
      )
  }


  del(endpoint: string, headers: HttpHeaders, auth: boolean): Observable<any> {
    if (auth) {
      headers = headers.set('Authorization', this.global.accessToken);
    }
    return this.http.delete(endpoint, { headers: headers }).
      pipe(catchError(error => {
        let httpError: string;
        if (error.error instanceof ErrorEvent) {
          httpError = `Error: ${error.error.message}`;
        } else {
          httpError = this.getServerErrorMessage(error);
        }
        console.log(httpError);
        return throwError(httpError);
      })
      )
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 401: {
        return "Please enter a valid username and password."
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }

    }
  }
  patch(endpoint: string, reqBody?:any, auth?: boolean): Observable<any> {
    let headers = new HttpHeaders();
    console.log(this.global.accessToken)
    if (auth) {
      headers = headers.set('Authorization', this.global.accessToken);
    }
    return this.http.patch(endpoint, reqBody, { headers: headers }).
      pipe(catchError(error => {
        let httpError: string;
        if (error.error instanceof ErrorEvent) {
          httpError = `Error: ${error.error.message}`;
        } else {
          httpError = this.getServerErrorMessage(error);
        }
        console.log(httpError);
        return throwError(httpError);
      })
      )
  }
}
