import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavMenuService } from '../nav-menu/nav-menu.service';
import { ConfirmedValidator } from '../shared/confirmed.validator';
import { SideMenuService } from '../side-menu/side-menu.service';
import { AlertService } from '../utility/alert.service';
import { GlobalProvider } from '../utility/global.service';
import { RestPasswordService } from './reset-password.service';
import { PasswordSetRequest } from './reset.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers:[RestPasswordService]
})
export class ResetPasswordComponent implements OnInit {
  passwordSettingForm: FormGroup;
  submitted:boolean;
  changePasswordId: string;
  loading = false;
  authError:string;
  
  constructor( private formBuilder: FormBuilder,private global: GlobalProvider,
   private router:Router,private alertService: AlertService,
   public nav:NavMenuService,   public menu:SideMenuService, private _activatedroute: ActivatedRoute, private rpService:RestPasswordService) { }

  ngOnInit(): void {
    console.log(this._activatedroute.snapshot.paramMap.get("changePasswordId"));

    this.changePasswordId = this._activatedroute.snapshot.paramMap.get("changePasswordId");

    this.passwordSettingForm = this.formBuilder.group({
      oldPassword: [''],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { 
      validator: ConfirmedValidator('newPassword', 'confirmPassword')
    });
    if(this.changePasswordId === null){
      this.passwordForm['oldPassword'].setValidators([Validators.required]);
      this.passwordForm['oldPassword'].updateValueAndValidity();
    }
    this.nav.hide();
    this.menu.hide();
  }
  get passwordForm() { return this.passwordSettingForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.passwordSettingForm.valid && this.changePasswordId) {
      let passwordRequest: PasswordSetRequest = {
        password: this.passwordSettingForm.controls.newPassword.value,
        changePasswordId: this.changePasswordId.trim()
      };
      this.rpService.passwordSet(passwordRequest)
        .subscribe((data) => {
          if (data.success === true) {
            this.alertService.success('Password has been set successfully.');
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 2000); 
          }
        },
          error => {
            this.authError = error;
            this.loading = false;
          });
    }
  }
  
}


