import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConversationComponent } from './conversation/conversation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { OnboardPractitionerComponent } from './onboard-practitioner/onboard-practitioner.component';
import { PractitionerComponent } from './practitioner/practitioner.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';



const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'onboard-practitioner', component: OnboardPractitionerComponent },
  { path: 'practitioner', component: PractitionerComponent},
  {path:'reset-password',component:ResetPasswordComponent},
  {path:'password-setting/:changePasswordId',component:ResetPasswordComponent},
  // {path:'conversation',component:ConversationComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
