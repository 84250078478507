import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalProvider } from '../utility/global.service';
import { HttpService } from '../utility/http.service';
import { LoginRequest } from './login.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  isLoading: any;

  constructor(
    public global: GlobalProvider,
    private httpSvc: HttpService
    ) { }

    login(loginReq: LoginRequest): Observable<any> {

      let authUrl = environment.authUrl;
      let headers = new HttpHeaders();

      console.log('Login Methos - Auth URL ->' + authUrl)
      return this.httpSvc.post(authUrl, loginReq, headers, false)
    }

}
