import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalProvider } from '../utility/global.service';
import { HttpService } from '../utility/http.service';

@Injectable({
  providedIn: 'root'
})
export class PractitionerService {
  apiUrl = environment.apiUrl;
  constructor(public global: GlobalProvider,
    private httpSvc: HttpService) { }

    reSendLink(req: any): Observable<any> {
    let headers = new HttpHeaders();
    return this.httpSvc.post(`${this.apiUrl}practitioner/v1/practitioner/resendOnboardingEmail`, req, headers, false)
  }
}
