import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OnboardPractitionerComponent } from './onboard-practitioner/onboard-practitioner.component';

import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { AlertComponent } from './alert/alert.component';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import {  NgxUiLoaderModule, NgxUiLoaderRouterModule} from 'ngx-ui-loader';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { LoaderService } from './utility/loader.service';
import { LoaderInterceptor } from './utility/loader.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { PractitionerComponent } from './practitioner/practitioner.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import {DragDropModule} from '@angular/cdk/drag-drop'
import { ConversationComponent } from './conversation/conversation.component';
import { JwtModule } from "@auth0/angular-jwt";

export function tokenGetter() {
  return localStorage.getItem("access_token");
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    OnboardPractitionerComponent,
    AlertComponent,
    NavMenuComponent,
    SideMenuComponent,
    LoaderComponent,
    PractitionerComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    // ConversationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxUiLoaderModule, 
    NgxUiLoaderRouterModule,
    DragDropModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["example.com"],
        disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
  ],
  providers: [
    LoaderService,
    {provide : HTTP_INTERCEPTORS, useClass: LoaderInterceptor,multi:true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
