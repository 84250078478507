<alert></alert>
<app-nav-menu></app-nav-menu>
<!-- <ngx-ui-loader></ngx-ui-loader> -->
<div id="layoutSidenav">
    <div id="layoutSidenav_nav">
        <app-side-menu></app-side-menu>
    </div>
    <div id="layoutSidenav_content">
        <router-outlet></router-outlet>
    </div>
</div>
<app-loader></app-loader>