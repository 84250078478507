<main>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5">
                <div class="card shadow-lg border-0 rounded-lg mt-5">
                    <div class="card-header">
                        <h3 class="text-center font-weight-light my-4 text-capitalize">Forgot Password</h3>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="forgotPasswordForm">
                            <div class="group  mb-3">
                                <label class="small mb-1">New Password</label>
                                <input type="text" class="form-control " type="text" formControlName="newPassword" name="newPassword" />
                                <div *ngIf="submitted && forgotPassword.newPassword.errors">
                                    <div *ngIf="forgotPassword.newPassword.errors.required" class="text-danger">This field is required</div>
                                </div>
                            </div>
                            <div class="group  mb-3">
                                <label class="small mb-1">Confirm Password</label>
                                <input type="text" class="form-control" type="text" formControlName="confirmPassword" name="confirmPassword" />
                                <div *ngIf="submitted && forgotPassword.confirmPassword.errors">
                                    <div *ngIf="forgotPassword.confirmPassword.errors.required" class="text-danger">This field your password</div>
                                    <div *ngIf="forgotPassword.confirmPassword.errors.confirmedValidator" class="text-danger">Password and Confirm Password must be match.</div>

                                </div>
                            </div>
                            <div class="text-center  mt-4 mb-0">
                                <button (click)="onSubmit()" class="btn btn-primary">Change Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>