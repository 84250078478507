import { Component, OnInit } from '@angular/core';

import { NavMenuService } from '../nav-menu/nav-menu.service';
import { SideMenuService } from '../side-menu/side-menu.service';
import { GlobalProvider } from '../utility/global.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DashboardService } from './dashboard.service';
import { AppointmentStatus } from './dashboard.model';
import { AlertService } from '../utility/alert.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers:[DashboardService]
})
export class DashboardComponent implements OnInit {
  newLeads = [{"id":56,"preferredTimeSlot":"9 AM to 1 PM","practitionerID":"62c20ef4-d5db-41d3-9aac-6783f89dbdd0","practiceID":245,"patientID":38,"questionnaire":[],"status":"Appointments booked","profile":{"id":38,"name":"John Doe","email":"john@gmail.com","phone":"9659342342","city":"Toronto","country":"usa"}}];
  appointmentsBooked = [];
  servicesSold = [];
  patientNoShow = [];
  followUp = [];

  constructor(
    public global:GlobalProvider,
    public nav:NavMenuService,private alert: AlertService,
    public menu:SideMenuService,private appoinmentService:DashboardService
    ) { }

    ngOnInit(): void {
      this.nav.show()
      this.menu.show();
      this.appoinmentService.getAllAppoinments().subscribe(res => {
        console.log(res);
        // this.newLeads = res.data.filter(obj => obj.status === 'New leads');
        this.appointmentsBooked = res.data.filter(obj => obj.status === 'Appointments booked');
        this.servicesSold = res.data.filter(obj => obj.status === 'Services Sold');
        this.patientNoShow = res.data.filter(obj => obj.status === 'Patient No-Show');
        this.followUp = res.data.filter(obj => obj.status === 'Followup');
      })
    }
    drop(event: CdkDragDrop<any>, appointmentStatus: string) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        let status: AppointmentStatus = {
          status: appointmentStatus
        }
        this.appoinmentService.updateAppointmentStatus(status, event.previousContainer.data[event.previousIndex]['id']).subscribe(res => {
          console.log(res);
          if (res && res.status === 'success') {
            transferArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);
          }
          else {
            this.alert.error('Sorry. Unable to process the request right now. Please try again later.')
          }
        }, error => {
          this.alert.error(error);
        });
  
      }
    }
}
