<main>
    <div class="container-fluid">
        <!-- <h4>PRACTITIONERS LIST</h4> -->
        <h4></h4>
        <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item active">PRACTITIONERS LIST</li>
        </ol>
    </div>
    <div class=" text-right">
        <a class="btn btn-primary m-2" routerLink="/onboard-practitioner">Add Practitioner</a>
    </div>
    <!-- <div class=" m-4 table-responsive-md">
        <table class="table table-bordered table-hover">
            <thead class="thead-dark">
                <tr>
                    <th>Practitioner Name</th>
                    <th>Practice Name</th>
                    <th>Location Count</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                </tr>
            </thead>
            <tr *ngFor="let practitioner of practitionerList">
                <td>{{practitioner.firstName}} {{practitioner.lastName}}</td>
                <td>{{practitioner.practiceName ? practitioner.practiceName : '-'}}</td>
                <td>{{practitioner.locationCount ? practitioner.locationCount : '-'}}</td>
                <td>{{practitioner.phone}}</td>
                <td>{{practitioner.email}}</td>
                <td [ngClass]="{'text-warning':practitioner.status === 'ONBOARDED'}" class="text-success">{{practitioner.status}}</td>
                <td>{{practitioner.createdAt | date:'dd-MM-yyy'}}</td>
                <td>{{practitioner.updatedAt | date:'dd-MM-yyy'}}</td>
            </tr>
        </table>
    </div> -->
    <div class="m-4">
        <p class="text-danger text-center">{{error}}</p>
        <div class="row">
            <div class="col-md-4 mb-4" *ngFor="let practitioner of practitionerList">
                <div class="card">
                    <div class="card-body">
                        <h6 class="name">{{practitioner.firstName}} {{practitioner.lastName}}
                            <small class="float-right text-success">{{practitioner.status | lowercase}}</small>
                        </h6>
                        <div>
                            <p class="text-muted practiceName"><small> {{practitioner.practiceName}}</small></p>
                            <p><small><i class="fa fa-envelope" aria-hidden="true"></i> {{practitioner.email}}</small></p>
                            <p><small><i class="fa fa-phone" aria-hidden="true"></i> {{practitioner.phone}}</small></p>
                        </div>
                        <hr/>
                        <div class="row text-center align-items-center">
                            <div class="col">
                                <p (click)="reSendLink(practitioner.email)" class="text-primary"> <small>Resend onboarding instructions </small></p>
                            </div>
                            <div class="col">
                                <p class="border-left"><small>Onboarded On <br/> {{practitioner.createdAt | date:'dd-MM-yyy'}}</small></p>

                            </div>

                            <!-- <div class="col">
                                <p><small>Updated At  <br/> {{practitioner.updatedAt | date:'dd-MM-yyy'}}</small></p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>