import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpService } from '../utility/http.service';
import { environment } from '../../environments/environment';
import { NavMenuService } from '../nav-menu/nav-menu.service';
import { SideMenuService } from '../side-menu/side-menu.service';
import { Router } from '@angular/router';
import { PractitionerService } from './practitioner.service';
import { AlertService } from '../utility/alert.service';

@Component({
  selector: 'app-practitioner',
  templateUrl: './practitioner.component.html',
  styleUrls: ['./practitioner.component.scss'],
  providers:[PractitionerService]
})
export class PractitionerComponent implements OnInit {

  apiUrl = environment.apiUrl;
  practitionerList : any = [];
  error:string;

  constructor(private http:HttpService,
    public nav:NavMenuService,
    public menu:SideMenuService,private router:Router, 
    private practitionerService:PractitionerService, private alertService:AlertService) { }

  ngOnInit(): void {
    this.nav.show()
    this.menu.show();

    let myRawToken = localStorage.getItem('time');
    setTimeout(() => {
      this.logout() 
     }, Number(myRawToken));

    this.http.get(`${this.apiUrl}practitioner/v1/practitioner/list`, true).subscribe(res =>{
      this.practitionerList = res.data;
      console.log(res);
    },
    error => {
      this.error = 'Records not found';
    });
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  reSendLink(link) {
    const obj={
      email:link
    }
    this.practitionerService.reSendLink(obj).subscribe(res =>{
      this.alertService.success(res?.message);
    }, error => {
      // this.error = "Something went wrong. Please try again.";
      this.alertService.error("Something went wrong. Please try again.");
    })
  }
}
