<main>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5">
                <p class="text-danger text-center">{{authError}}</p>
                <div class="card shadow-lg border-0 rounded-lg mt-5">
                    <div class="card-header">
                        <h3 class="text-center font-weight-light my-4 text-capitalize">Change Password</h3>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="passwordSettingForm">
                            <div class="group  mb-2" *ngIf="changePasswordId === null">
                                <label class="small mb-1">Old Password</label>
                                <input type="text" class="form-control" type="text" formControlName="oldPassword" name="oldPassword" />
                                <div *ngIf="submitted && passwordForm.oldPassword.errors">
                                    <div *ngIf="passwordForm.oldPassword.errors.required" class="text-danger">This field is required.</div>
                                </div>
                            </div>
                            <div class="group  mb-2">
                                <label class="small mb-1">New Password</label>
                                <input type="text" class="form-control" type="text" formControlName="newPassword" name="newPassword" />
                                <div *ngIf="submitted && passwordForm.newPassword.errors">
                                    <div *ngIf="passwordForm.newPassword.errors.required" class="text-danger">This field is required.</div>
                                </div>
                            </div>
                            <div class="group  mb-2">
                                <label class="small mb-1">Confirm Password</label>
                                <input type="text" class="form-control" type="text" formControlName="confirmPassword" name="confirmPassword" />
                                <div *ngIf="submitted && passwordForm.confirmPassword.errors">
                                    <div *ngIf="passwordForm.confirmPassword.errors.required" class="text-danger">This field is required.</div>
                                    <div *ngIf="passwordForm.confirmPassword.errors.confirmedValidator" class="text-danger">Password and Confirm Password must be match.</div>
                                </div>
                            </div>
                            <div class="text-center  mt-4 mb-0">
                                <button (click)="onSubmit()" class="btn btn-primary">Reset Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>