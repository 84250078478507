<body class="bg">
    <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
            <main>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-5">
                            <div class="card shadow-lg border-0 rounded-lg mt-5">
                                <div class="card-header">
                                    <h3 class="text-center font-weight-light my-4">Login</h3>
                                </div>
                                <div class="card-body">
                                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                        <div class="group mb-3">
                                            <label class="small mb-1" for="inputEmailAddress">Email</label>
                                            <input type="text" formControlName="username" class="form-control" type="email" placeholder="Enter email address" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                                <div *ngIf="f.username.errors.required">Username is required</div>
                                            </div>
                                        </div>
                                        <div class="form-group mb-3">
                                            <label class="small mb-1" for="inputPassword">Password</label>
                                            <input type="password" placeholder="Enter password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input class="custom-control-input" id="rememberPasswordCheck" type="checkbox" />
                                                <label class="custom-control-label" for="rememberPasswordCheck">Remember password</label>
                                            </div>
                                        </div>
                                        <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                            <a class="small" routerLink="/forgot-password">Forgot Password?</a>
                                            <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading"  class="spinner-border spinner-border-sm mr-1"  ></span>
                        Login
                      </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</body>