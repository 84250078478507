import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AlertService } from '../utility/alert.service';
import { HttpService } from '../utility/http.service';
import { FORM } from './onboard-practitioner.model';
import { OnboardPractitionerService } from './onboard-practitioner.service';

@Component({
  selector: 'app-onboard-practitioner',
  templateUrl: './onboard-practitioner.component.html',
  styleUrls: ['./onboard-practitioner.component.scss']
})
export class OnboardPractitionerComponent implements OnInit {
  apiUrl = environment.apiUrl;
  form:FormGroup;
  submitted = false;
  status:boolean;
  error:boolean;

  constructor(
    private formBuilder: FormBuilder, 
    private alertService: AlertService,
    private onBoardService:OnboardPractitionerService,
    private router: Router,
    private http:HttpService
    ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      // zipCode: ['', Validators.required]
    });
    let myRawToken = localStorage.getItem('time');
    setTimeout(() => {
      this.logout() 
     }, Number(myRawToken));

  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    let body  = {
      firstName: this.f.firstname.value,
      lastName: this.f.lastname.value,
      email: this.f.email.value,
      phone: this.f.phoneNumber.value,
    }
    
    let headers = new HttpHeaders();
    this.http.post(`${this.apiUrl}practitioner/v1/practitioner/`, body, headers,true)
      .subscribe((data) => {
        console.log(data);
        if(data.status){
          this.router.navigate(['/practitioner']);
          this.status = true;
         this.alertService.success("Added successfully");
        }
   }, error =>{
    this.error = true;
    this.alertService.error("Email id already in use.");

   });
  //  if(this.form.valid){
  //   console.log("Form Submitted");
  //   this.alertService.success("Added successfully");
  //   this.form.reset();
  // }
  }
  onCancel(){
    this.router.navigate(['/practitioner']);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
