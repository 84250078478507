import { Injectable } from '@angular/core';
import { GlobalProvider } from '../utility/global.service';
import { HttpService } from '../utility/http.service';
import { AppointmentStatus } from './dashboard.model';
import { environment } from 'src/environments/environment';


@Injectable()
export class DashboardService {
  constructor(private http: HttpService, private global: GlobalProvider) { }

  getAllAppoinments() {
    let practitionerID = localStorage.getItem('practitionerID');
    return this.http.get(environment.apiUrl + 'patient/form/submissions?practitionerID=' + practitionerID, true)
  }

  updateAppointmentStatus(statusRequest: AppointmentStatus, practiceId: number) {
    return this.http.patch(this.global.patientServiceEndpoint.concat('/submission/' + practiceId + '/status'), statusRequest, true)

  }
}
