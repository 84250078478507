import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavMenuService } from './nav-menu.service';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  constructor(public nav: NavMenuService, private router:Router) { }

  ngOnInit(): void {
    let myRawToken = localStorage.getItem('time');
    setTimeout(() => {
      this.logout() 
     }, Number(myRawToken));
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
